import { createSlice } from "@reduxjs/toolkit";
import { createGoalAsync, deleteGoalAsync, updateGoalAsync } from "../settings";
import {
  bulkCreateTemplateAsync,
  bulkUpdateTemplateAsync,
  createTemplateAsync,
  deleteErrorsHistoryAsync,
  deleteTemplateAsync,
  setTemplateToHome,
  updateTemplateAsync,
} from "../admin";
import {
  createInitiativeAsync,
  deleteInitiativeAsync,
  restoreInitiativeAsync,
  updateInitiativeAsync,
} from "../initiatives";
import { createIdeationsAsync, deleteIdeationAsync } from "../ideation";
import {
  bulkCreateTaskAsync,
  createTaskAsync,
  deleteTaskAsync,
  updateTaskAsync,
} from "../task";

import { deleteHuddleAsync } from "../huddle";
import { saveFilters } from "../teamView";

const initialState = {
  requestSuccessesMessage: "",
  requestErrorMessage: "",
};

const successOrErrorSlice = createSlice({
  name: "successOrError",
  initialState,
  reducers: {
    resetStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setTemplateToHome.rejected, (state, action) => {
        state.requestErrorMessage = action.error?.message;
      })
      .addCase(setTemplateToHome.fulfilled, (state, action) => {
        state.requestSuccessesMessage =
          action.payload?.message[0].toUpperCase() +
          action.payload?.message.slice(1);
      })
      .addCase(createInitiativeAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(createInitiativeAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(updateInitiativeAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(updateInitiativeAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(deleteInitiativeAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(deleteInitiativeAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(restoreInitiativeAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(restoreInitiativeAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(deleteErrorsHistoryAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = "System errors successfully created";
      })
      .addCase(deleteErrorsHistoryAsync.rejected, (state, action) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(createTemplateAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(createTemplateAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(bulkCreateTemplateAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(bulkCreateTemplateAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(updateTemplateAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(updateTemplateAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(bulkUpdateTemplateAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(bulkUpdateTemplateAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(deleteTemplateAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(deleteTemplateAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      //
      .addCase(createIdeationsAsync.rejected, (state, action) => {
        state.requestErrorMessage = "Something went wrong";
      })
      .addCase(deleteIdeationAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = "Ideation successfully deleted";
      })
      .addCase(deleteIdeationAsync.rejected, (state, action) => {
        state.requestErrorMessage = "Something went wrong";
      })
      //
      .addCase(createGoalAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(createGoalAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(updateGoalAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(updateGoalAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(deleteGoalAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(deleteGoalAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(createTaskAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(createTaskAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(bulkCreateTaskAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(bulkCreateTaskAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(updateTaskAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(updateTaskAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(deleteTaskAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(deleteTaskAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      })
      .addCase(deleteHuddleAsync.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.payload?.message;
      })
      .addCase(saveFilters.fulfilled, (state, action) => {
        state.requestSuccessesMessage = action.meta.arg?.clearValues
          ? "Filters cleared. Showing all results now."
          : "Filters saved successfully!";
      })
      .addCase(deleteHuddleAsync.rejected, (state, action) => {
        state.requestErrorMessage = action.payload?.message;
      });
  },
});
export const { resetStore } = successOrErrorSlice.actions;

//Selectors
export const getRequestSuccessesMessage = (state) =>
  state.successOrError.requestSuccessesMessage;
export const getRequestErrorMessage = (state) =>
  state.successOrError.requestErrorMessage;

export default successOrErrorSlice.reducer;
