import "./InitiativeMobileBar.scss";
import { useCallback, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { getClassNameByParam } from "../../../Utils";
import { getCurrentUser } from "../../../Store/slices/user";
import { ReactComponent as Home } from "../../../Assets/Icons/Home.svg";
import { ReactComponent as Analytics } from "../../../Assets/Icons/Analytics.svg";
import { ReactComponent as Admin } from "../../../Assets/Icons/Admin.svg";
import { ReactComponent as Setting } from "../../../Assets/Icons/Setting.svg";
import { ReactComponent as Initiatives } from "../../../Assets/Icons/Initiatives.svg";
import { ReactComponent as TeamsView } from "../../../Assets/Icons/TeamsView.svg";
import { useTranslation } from "react-i18next";

const InitiativeMobileBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useSelector(getCurrentUser);
  const { t } = useTranslation();

  const topTabs = useMemo(
    () => [
      {
        title: `${t("home", { ns: ["global"] })}`,
        to: `/home`,
        icon: <Home width={16} height={16} className={"panel-icon"} />,
      },
      {
        title: `${t("initiatives", { ns: ["global"] })}`,
        to: `/initiatives`,
        icon: <Initiatives width={16} height={16} className={"panel-icon"} />,
      },
      {
        title: `Team View`,
        to: `/team-view`,
        icon: <TeamsView width={16} height={16} className={"panel-icon"} />,
      },
      {
        title: `${t("analytics", { ns: ["global"] })}`,
        to: `/initiatives/analytics`,
        icon: <Analytics width={16} height={16} className={"panel-icon"} />,
      },
    ],
    [t]
  );

  const bottomTabs = useMemo(
    () => [
      {
        title: "Admin",
        to: `/admin/?system-errors`,
        icon: <Admin width={16} height={16} className={"panel-icon"} />,
        isHidden: !user?.isAdmin,
      },

      {
        title: "Settings",
        to: `/settings?settings`,
        icon: <Setting width={16} height={16} className={"panel-icon"} />,
        isHidden: false,
      },
    ],
    [user?.isAdmin]
  );

  const onOutsideClick = useCallback((e) => {
    if (e.target.attributes.id) {
      setIsMenuOpen(false);
    }
  }, []);

  return (
    <div
      id={"home"}
      onClick={onOutsideClick}
      className={`initiative-mobile-bar ${getClassNameByParam(
        isMenuOpen,
        "initiative-mobile-bar--open"
      )}`}
    >
      <div
        className={`${getClassNameByParam(
          isMenuOpen,
          "initiative-mobile-bar--header-open"
        )}`}
      >
        <div
          className={`menu-btn ${getClassNameByParam(isMenuOpen, "open")}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className="menu-btn__burger"></div>
        </div>
      </div>

      <div
        className={`initiative-mobile-bar--items ${getClassNameByParam(
          isMenuOpen,
          "initiative-mobile-bar--items-open"
        )}`}
      >
        <div>
          {topTabs.map((tab) => (
            <NavLink
              key={tab.to}
              className={`initiative-bar-container-navLink`}
              to={tab.to}
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="initiative-bar-container-icon">{tab.icon}</div>
              <div className="initiative-bar-container-title">{tab.title}</div>
            </NavLink>
          ))}
        </div>

        <div>
          {bottomTabs.map((tab) => {
            if (tab.isHidden) {
              return null;
            }
            return (
              <NavLink
                key={tab.to}
                className={`initiative-bar-container-navLink`}
                to={tab.to}
                onClick={() => setIsMenuOpen(false)}
              >
                <div className="initiative-bar-container-icon">{tab.icon}</div>
                <div className="initiative-bar-container-title">
                  {tab.title}
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InitiativeMobileBar;
