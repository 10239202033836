import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUser, getSelectedWorkforceName } from "../Store/slices/user";
import Auth from "../Pages/Auth";
import BaseLayout from "../Layout/BaseLayout/BaseLayout";
import MainLayout from "../Layout/MainLayout/MainLayout";
import IdeationSetup from "../Components/IdeationSettingsExploreWrapper/IdeationSetup/IdeationSetup";
import IdeationResults from "../Components/IdeationSettingsExploreWrapper/IdeationResults/IdeationResults";
import NotfoundPage from "../Pages/NotFoundPage";
import LoadingPage from "../Pages/LoadingPage";

const Home = lazy(() => import("../Pages/Home/Home"));
const Ideation = lazy(() => import("../Pages/Ideation/Ideation"));
const Workforce = lazy(() => import("../Pages/Workforce"));
const Initiatives = lazy(() => import("../Pages/Initiatives/Initiatives"));
const Initiative = lazy(() => import("../Pages/Initiative/Initiative"));
const Tasks = lazy(() => import("../Pages/Tasks"));
const Huddles = lazy(() => import("../Pages/Huddles"));
const Ideations = lazy(() => import("../Pages/Ideations/Ideations"));
const CreateOrEditInitiative = lazy(() =>
  import("../Pages/CreateOrEditInitiative/CreateOrEditInitiative")
);
const IdeationSettingsExplore = lazy(() =>
  import("../Pages/IdeationSettingsExplore/IdeationSettingsExplore")
);
const Analytics = lazy(() => import("../Pages/Analytics/Analytics"));
const Admin = lazy(() => import("../Pages/Admin/Admin"));
const Template = lazy(() => import("../Pages/Template/Template"));
const Settings = lazy(() => import("../Pages/Settings"));
const TeamView = lazy(() => import("../Pages/TeamView/TeamView"));

export const Routers = () => {
  const currentUser = useSelector(getCurrentUser);
  const selectedWorkforceName = useSelector(getSelectedWorkforceName);
  return (
    <Routes>
      <Route
        path="/empower/ideation/:ideationId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Ideation />
          </Suspense>
        }
      />
      <Route path="/authorization/callback" element={<Auth />} />
      <Route
        path="/empower/draft/:ideationId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Ideation status={"draft"} />
          </Suspense>
        }
      />

      <Route path="/" element={<BaseLayout />}>
        {["/", "/workforces"].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <Suspense fallback={<LoadingPage />}>
                <Workforce />
              </Suspense>
            }
          />
        ))}

        {selectedWorkforceName ? (
          <>
            <Route
              path="/home"
              element={
                <MainLayout>
                  <Suspense fallback={<LoadingPage />}>
                    <Home />
                  </Suspense>
                </MainLayout>
              }
            />
            <Route
              path="/initiatives"
              element={
                <MainLayout>
                  <Suspense fallback={<LoadingPage />}>
                    <Initiatives />
                  </Suspense>
                </MainLayout>
              }
            />
            <Route
              path="/initiatives/:status/:initID"
              element={
                <MainLayout>
                  <Suspense fallback={<LoadingPage />}>
                    <Initiative />
                  </Suspense>
                </MainLayout>
              }
            >
              <Route
                path="tasks"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <Tasks />
                  </Suspense>
                }
              />
              <Route
                path="huddles"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <Huddles />
                  </Suspense>
                }
              />
              <Route
                path="ideation"
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <Ideations />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="/team-view"
              element={
                <MainLayout>
                  <Suspense fallback={<LoadingPage />}>
                    <TeamView />
                  </Suspense>
                </MainLayout>
              }
            />

            {["new-initiative", "update-initiative"].map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <CreateOrEditInitiative />
                  </Suspense>
                }
              />
            ))}

            <Route
              path="/ideation"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <IdeationSettingsExplore />
                </Suspense>
              }
            >
              <Route path="setup" element={<IdeationSetup />} />
              <Route path="results" element={<IdeationResults />} />
            </Route>

            <Route
              path="/analytics"
              element={
                <MainLayout>
                  <Suspense fallback={<LoadingPage />}>
                    <Analytics />
                  </Suspense>
                </MainLayout>
              }
            />

            {currentUser?.isAdmin && (
              <Route
                path="/admin"
                element={
                  <MainLayout>
                    <Suspense fallback={<LoadingPage />}>
                      <Admin />
                    </Suspense>
                  </MainLayout>
                }
              />
            )}
            {["new-template", "update-template"].map((path) => (
              <Route
                path={path}
                key={path}
                element={
                  <Suspense fallback={<LoadingPage />}>
                    <Template />
                  </Suspense>
                }
              />
            ))}
            <Route
              path="/settings"
              element={
                <MainLayout>
                  <Suspense fallback={<LoadingPage />}>
                    <Settings />
                  </Suspense>
                </MainLayout>
              }
            />
          </>
        ) : (
          <>
            <Route path="*" element={<Navigate to="/workforces" />} />
          </>
        )}

        <Route path="*" element={<NotfoundPage />} />
      </Route>
    </Routes>
  );
};
