import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/user/index";
import empowerSlice from "./slices/empower/index";
import initiativesSlice from "./slices/initiatives";
import initiativeSlice from "./slices/initiative";
import homeSlice from "./slices/home";
import tasksSlice from "./slices/task";
import huddleSlice from "./slices/huddle";
import ideationSlice from "./slices/ideation";
import analyticsSclice from "./slices/analytics";
import adminSlice from "./slices/admin/index";
import settingsSlice from "./slices/settings/index";
import initiativeAnalyticsSlice from "./slices/initiativeAnalytics/index";
import successOrErrorSlice from "./slices/successOrError/successOrError";
import teamView from "./slices/teamView";

const rootReducer = combineReducers({
  user: userSlice,
  empower: empowerSlice,
  initiatives: initiativesSlice,
  initiative: initiativeSlice,
  tasks: tasksSlice,
  huddle: huddleSlice,
  ideation: ideationSlice,
  analytics: analyticsSclice,
  admin: adminSlice,
  setting: settingsSlice,
  initiativeAnalytics: initiativeAnalyticsSlice,
  successOrError: successOrErrorSlice,
  home: homeSlice,
  teamView: teamView,
});

export const store = configureStore({
  reducer: rootReducer,
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};
